<template>
  <div class="pt-3">
    <b-nav pills>
      <template v-for="season in channel_data.seasons">
        <b-nav-item 
          :key="season.id" 
          :to="'/c/' + channel +'/scores/' + season.id" 
          :active="season.id == scoreset && $route.name==='channel_scoreset'">
          {{ season.title }}
        </b-nav-item>      
      </template>
      <b-nav-item :to="'/c/' + channel +'/scores'" :active="!scoreset && $route.name==='channel_scores'" >Current season</b-nav-item>
    </b-nav>
    <b-table striped hover :items="channel_data.scores">
    </b-table>
  </div>
</template>

<script>
  import axios from 'axios';
  export default {
    name: 'Channel',
    props: ['channel', 'scoreset'],
    data() {
      return {
        fields: [{
          key: 'question_text',
          label: 'Recently asked questions'
        }],
        channel_data: {
        },
        variants: [
          "primary",
          "info",
          "success"
        ]
      };
    },    
    created: function() {
      this.getscores()
    },
    methods: {
      getscores: function() {
        var url = process.env.VUE_APP_API_ROOT + '/api/channels/' + this.channel + '/scores/'
        if (this.scoreset) {
          url = url + this.scoreset + '/'
        }
        axios.get(url)
          .then(res => {
            this.channel_data = res.data
            // console.log(res.data)
          })    
      }
    },
    watch: {
      $route() {
        // react to route changes...
        this.getscores()
      }
    }    
  }
</script>

<style>
</style>

<template>
  <div class="q" id="qcontainer">
    <strong>Public channels:</strong>
    <div>
      <template v-for="(channel, index) in channels_data">
        <b-button :key="channel.name" :variant="variants[index % variants.length]" :to="'/c/' + channel.name">{{ channel.name }}</b-button>
      </template>
    </div>
  </div>

</template>

<script>
  import axios from 'axios';
  export default {
    name: 'ChannelsList',
    data() {
      return {
        channels_data: [],
        variants: [
          "success",
          "primary",
          "info"
        ]
      };
    },
    created: function() {
            axios
                .get(process.env.VUE_APP_API_ROOT + '/api/channels/')
                .then(res => {
                  this.channels_data = res.data
                  // console.log(res.data)
                })
    },
  }
  
</script>

<style>
  h3 {
    margin-bottom: 5%;
  }
</style>

<template>
  <div class="q" id="qcontainer">
    <strong>You can also view questions from a variety of categories</strong>
    <div>
      <template v-for="(tag, index) in tags_data">
        <b-button :key="tag.slug" :variant="variants[index % variants.length]" :to="'/t/' + tag.slug">{{ tag.slug }} ({{ tag.count }})</b-button>
      </template>
    </div>
  </div>

</template>

<script>
  import axios from 'axios';
  export default {
    name: 'TagsList',
    data() {
      return {
        tags_data: [],
        variants: [
          "primary",
          "info",
          "success"
        ]
      };
    },
    created: function() {
            axios
                .get(process.env.VUE_APP_API_ROOT + '/api/tags/')
                .then(res => {
                  this.tags_data = res.data
                  // console.log(res.data)
                })
    },
  }
  
</script>

<style>
</style>

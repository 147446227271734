<template>
    <div>
      <b-breadcrumb>
        <b-breadcrumb-item to="/">
          <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
          Home
        </b-breadcrumb-item>
        <b-breadcrumb-item :to="'/c/' + aq.channel_name">
          Channel {{ aq.channel_name }}
        </b-breadcrumb-item>
        <b-breadcrumb-item active>
          Question asked on {{ aq.date }}
        </b-breadcrumb-item>
      </b-breadcrumb>      
      <div v-if="aq.channel_type=='TWITTER'" class="q">
        <Tweet :id="aq.ext_ref_id">
          {{ aq.question_text }}
        </Tweet>
      </div>
      <div v-if="aq.channel_type=='MASTODON'" class="q">
          <iframe style="width:100%" :src="aq.ext_ref_id + '/embed'"></iframe>
      </div>
      <div v-if="aq.channel_type!=='TWITTER'" class="q">
        {{ aq.question_text }}
      </div>
      <strong>Accepted answers: {{ aq.answers_display }}</strong>
      <b-table striped hover :items="aq.subans">
      </b-table>


    </div>
</template>

<script>
import axios from 'axios';
import { Tweet } from 'vue-tweet-embed'

export default {
    name: 'AskedQuestion',
    props: ['id'],
    components: { Tweet },
    data: function() {
      return {
        aq: {}
      }
    },
    created: function() {
      axios.get(process.env.VUE_APP_API_ROOT + '/api/askedq/' + this.id + '/')
        .then(res => {
          // console.log(res.data)
          this.aq = res.data
        })    
    }  
}
</script>

<style>
</style>

<template>
    <div class="container">
      <b-jumbotron header="Trivia Storm!" lead="A storm of trivia">
        <!-- component matched by the route will render here -->
        <router-view></router-view>
      </b-jumbotron>

      <footer class="footer">
        <b-link href="/blog/">Blog</b-link> // 
        <b-link href="https://roytang.net/2017/02/weekend-project-twitter-trivia-bot/">About Triviastorm</b-link> //
        <b-link href="https://twitter.com/triviastorm/">https://twitter.com/triviastorm/</b-link> //
        <b-link rel="me" href="https://botsin.space/@triviastorm">https://botsin.space/@triviastorm</b-link>
        <p>&copy; <a href="https://roytang.net">Roy Tang</a></p>
      </footer>

    </div> <!-- /container -->  
</template>

<script>

export default {
  name: 'App',
  components: {
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>

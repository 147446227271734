<template>
    <div>
      <b-breadcrumb>
        <b-breadcrumb-item active>
          <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
          Home
        </b-breadcrumb-item>
      </b-breadcrumb>      
      <AskQuestion></AskQuestion>
      <ChannelsList></ChannelsList>
      <TagsList></TagsList>
    </div>
</template>

<script>
import AskQuestion from '../components/AskQuestion.vue'
import TagsList from '../components/TagsList.vue'
import ChannelsList from '../components/ChannelsList.vue'

export default {
  components: {
    AskQuestion,
    ChannelsList,
    TagsList
  }
}
</script>

<style>
</style>

import Vue from 'vue'

Vue.config.productionTip = false

// bootstrap setup https://bootstrap-vue.org/docs
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

// router setup
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import App from './App.vue'
import routes from './routes.js'
const router = new VueRouter({
  routes // short for `routes: routes`
})


new Vue({
  render: h => h(App),
  router
}).$mount('#app')

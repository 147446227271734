<template>
  <div>
    <div v-if="channel_data.object.channel_type=='TWITTER'">
      <p>TriviaStorm twitter bot is currently deployed at <a :href="'http://twitter.com/' + channel_data.object.host">twitter.com/{{ channel_data.object.host }}</a>. It posts a trivia question on a regular basis, just reply with your answer.</p>

      <p>How it works: The bot tweets out a trivia question once every hour. It will then award points to the people who gave the correct answer. The bot will only recognize answers given as a direct reply to the tweet with the question, and only those submitted within the one hour period.</p>

      <p>This Bot is currently in ALPHA. Your help in testing it is appreciated!</p>
    </div>
    <b-table striped hover :items="channel_data.questions" :fields="fields">
      <template #cell(question_text)="data">
        <b-link :to="'/aq/' + data.item.id">{{ data.value }}</b-link>
      </template>      
    </b-table>
    <table class="table table-striped">
      <tr><th>Channel stats</th><td>(for this season)</td></tr>
      <tr><th>Total question pool</th><td>{{ channel_data.stats.total }}</td></tr>
      <tr><th>Unasked questions</th><td>{{ channel_data.stats.unasked }}</td></tr>
      <tr><th>Asked questions</th><td>{{ channel_data.stats.asked }}</td></tr>
      <tr><th>Questions asked more than once</th><td>{{ channel_data.stats.dupes }}</td></tr>
    </table>
  </div>
</template>

<script>
  import axios from 'axios';
  export default {
    name: 'Channel',
    props: ['channel'],
    data() {
      return {
        fields: [{
          key: 'question_text',
          label: 'Recently asked questions'
        }],
        channel_data: {
          object: {
            host: '',
            channel_type: '',
          },
          questions: [],
          stats: {}
        },
        variants: [
          "primary",
          "info",
          "success"
        ]
      };
    },    
    created: function() {
      axios.get(process.env.VUE_APP_API_ROOT + '/api/channels/' + this.channel + '/')
        .then(res => {
          this.channel_data = res.data
        })    
    }
  }
</script>

<style>
</style>

import Home from './pages/Home.vue'
import Tag from './pages/Tag.vue'
import Channel from './pages/Channel.vue'
import ChannelHome from './pages/ChannelHome.vue'
import ChannelScores from './pages/ChannelScores.vue'
import AskedQuestion from './pages/AskedQuestion.vue'

const routes = [
  { path: '/', component: Home },
  { path: '/aq/:id', component: AskedQuestion, props: true },
  { path: '/t/:tag', component: Tag, props: true },
  { 
    path: '/c/:channel', 
    component: Channel, 
    props: true,
    children: [
      { path: '', component: ChannelHome, props: true, name: 'channel_home' },
      { path: 'scores', component: ChannelScores, props: true, name: 'channel_scores' },
      { path: 'scores/:scoreset', component: ChannelScores, props: true, name: 'channel_scoreset' }
    ]
  }
]

export default routes
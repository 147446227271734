<template>
  <div>
    <b-breadcrumb>
      <b-breadcrumb-item to="/">
        <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
        Home
      </b-breadcrumb-item>
      <b-breadcrumb-item active>
        Channel {{ channel }}
      </b-breadcrumb-item>
    </b-breadcrumb>      

    <b-nav tabs>
      <b-nav-item :to="'/c/' + channel" :active="$route.name==='channel_home'" >Channel Details</b-nav-item>
      <b-nav-item :to="'/c/' + channel +'/scores'" 
        :active="$route.name==='channel_scores' || $route.name==='channel_scoreset'">Scores</b-nav-item>
    </b-nav>
    <router-view></router-view>
  </div>
</template>

<script>
  export default {
    name: 'Channel',
    props: ['channel'],
    data() {
      return {
      };
    },    
    created: function() {
    }
  }
</script>

<style>
</style>

<template>
  <div>
    <b-breadcrumb>
      <b-breadcrumb-item to="/">
        <b-icon icon="house-fill" scale="1.25" shift-v="1.25" aria-hidden="true"></b-icon>
        Home
      </b-breadcrumb-item>
      <b-breadcrumb-item active>
        Questions tagged {{ tag }}
      </b-breadcrumb-item>
    </b-breadcrumb>      

    <AskQuestion :tag="tag"></AskQuestion>
  </div>
</template>

<script>
import AskQuestion from '../components/AskQuestion.vue'

export default {
  name: 'Tag',
  props: ['tag'],
  components: {
    AskQuestion,
  },
  watch: {
    $route(to, from) {
      // react to route changes...
      console.log("Route", to, from)
    }
  }  
}
</script>

<style>
</style>

<template>
  <div class="q pb-4" id="qcontainer">
    <strong>Random question chosen from a pool of <span id="total_count">{{ question.total_count }}</span>:</strong>
    <p id="question_text">{{ question.text }}</p>
    <b-img :src="question.attachment" v-if="question.attachment" />
    <b-form-textarea
      id="textarea"
      v-model="answer"
      placeholder="Type your answer here..."
      rows="3"
      max-rows="6"
    ></b-form-textarea>    
    <br/>
    <b-button variant="primary" v-on:click="checkanswer">Submit your answer</b-button>
    <b-button variant="info" v-on:click="newquestion">Get a new question</b-button>
    <div v-if="checked && !correct">
      Sorry, you are <span class="incorrect">WRONG!</span>
      Acceptable answer(s) are: {{ answers_display }}
    </div>
    <div v-if="checked && correct">Correct!</div>
  </div>

</template>

<script>
  import axios from 'axios';
  export default {
    name: 'AskQuestion',
    props: ['tag'],
    data() {
      return {
        answer: "",
        answers_display: "",
        correct: false,
        checked: false,
        question: {},
      };
    },
    created: function() {
      this.newquestion()
    },
    methods: {
        newquestion: function() {
          var url = process.env.VUE_APP_API_ROOT + '/api/getq/'
          if (this.tag) {
            url = url + '?tag=' + this.tag
          }
            axios
                .get(url)
                .then(res => {
                    this.question = res.data
                    this.checked = false,
                    this.answer = ""
                })
        },
        checkanswer: function() {
            axios
                .get(process.env.VUE_APP_API_ROOT + '/api/checkanswer/' + this.question.id + '/?ans=' + this.answer)
                .then(res => {
                  // console.log(res.data);
                  this.checked = true;
                  this.correct = res.data.correct;
                  this.answers_display = res.data.answers.join("; ")
                })
        }
    }
  }
  
</script>

<style>
  h3 {
    margin-bottom: 5%;
  }
</style>
